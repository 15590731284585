import React from 'react'
import I18n from '../utils/I18n'
import Translation from '../utils/Translation'
import certifications from '../../data/certifications.json'

const CertificationBanner = ({data}) => {
  const {slug, title, roleImg, lang} = data

  const i18n = new I18n({ lang })
  const breadcrumbTitle = title.replace(/<[^>]*>/g, ' ')
  const itemEN = Translation.getItems(certifications, null, 'slug', 'en').find(it => it.slug === slug)
  const customCssClass = {
    'associate': 'associate',
    'aiassociate': 'associate',
    'mulesoftassociate': 'associate'
  }

  const backgroundClass = Object.keys(customCssClass).includes(slug) ? `certification-banner_${customCssClass[slug]}` : ''
  const heroImageClass = Object.keys(customCssClass).includes(slug) ? `certification-banner_image_${customCssClass[slug]}` : ''

  return (
    <div className={`certification-banner ${backgroundClass} slds-is-relative`}>
      <div className="certification-banner_breadcrumb">
        <a href={`/${lang}/credentials/administratoroverview`}>{i18n.text('breadcrumb_root')}</a> &gt; {breadcrumbTitle}
      </div>
      <img className={`certification-banner_image ${heroImageClass}`} src={itemEN ? itemEN.roleImg : roleImg} />
      <div className="certification-banner-text">
        <div>
          <div className="certification-banner_title slds-container--medium slds-container--center slds-text-align--center"  dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>
    </div>
  )
}

export default CertificationBanner
