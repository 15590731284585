import React from 'react';
import Layout from '../layouts/Layout';
import PropTypes from 'prop-types';
import CertificationsBanner from '../components/CertificationsBanner';
import AboutExam from '../components/AboutExam';
import Prerequisites from '../components/Prerequisites';
import StudyPrepare from '../components/StudyPrepare';
import JoinCommunity from '../components/JoinCommunity';
import LearnSkills from '../components/LearnSkills';
import EarnCredential from '../components/EarnCredential';
import EarnCredentialTemp from '../components/EarnCredentialTemp';
import MaintenanceExams from '../components/MaintenanceExams';
import ReviewBoards from '../components/ReviewBoards';
import Credentials from '../components/Credentials';
import SeparatorDots from '../components/SeparatorDots';

import I18n from '../utils/I18n';

export const CertificationsTemplate = item => {
  const {
    pageContext: {
      data,
      data:{
        slug,
        title,
        roleImg,
        aboutExam_customTitle,
        aboutExam_description,
        guide,
        pathToCertification,
        prerequisites,
        prerequisitesText,
        studyPrepare,
        studyPrepareText,
        skills,
        earnCredential,
        maintainCredential,
        reviewBoard,
        credentialsBadges
      },
      lang
    }
  } = item;
  const i18n = new I18n({ lang });
  let aboutTheExamCustomCta = null;

  if(slug === "technicalarchitect") {
    aboutTheExamCustomCta = {
      label: i18n.text('about_the_exam__architect_review_board'),
      link: "credentials/architectreviewboard/"
    };
  }

  return (
    <Layout title={title} lang={lang} slug={slug}>
      <div>
        <CertificationsBanner data={data} />
        <div className="cert-site-bg--trailhead-icons slds-p-bottom--x-large">
          {aboutExam_description != '' && (
            <>
              <AboutExam 
                lang={lang} 
                customTitle={aboutExam_customTitle} 
                description={aboutExam_description} 
                guide={guide} 
                customCta={aboutTheExamCustomCta}
                slug={slug}
              />
              <SeparatorDots />
            </>
          )}

          { prerequisites.length > 0 && (
            <>
              <Prerequisites data={prerequisites} text={prerequisitesText} lang={lang} title={title} slug={slug}/>
              <SeparatorDots />
            </>
          )}

          { pathToCertification && typeof(pathToCertification) === "object" && (
            <>
              <div className="slds-container--center slds-container--medium slds-m-bottom--x-large slds-is--relative Pt(1px)">
                <div className="cs-box-1 H(100%) slds-is-relative  slds-m-top_large ">
                  <div className="slds-text-align--center slds-m-bottom--x-small  Fz(24px) Ff(NeutrafaceDisplay-Titling)">
                    {i18n.text('pathToCertification_title')}
                  </div>

                  <div className="Fz(18px) slds-text-align--center slds-m-bottom--small">
                    {pathToCertification.text}
                  </div>
                  <img alt={title} className="slds-m-top_medium" src={pathToCertification.image} />
                </div>
              </div>
              <SeparatorDots />
            </>
          )}

          { studyPrepare.length > 0 && (
            <>
              <StudyPrepare data={studyPrepare} text={studyPrepareText} lang={lang} />
              <SeparatorDots />
            </>
          )}

          <JoinCommunity lang={lang} slug={slug} />

          <SeparatorDots />

          { skills.length > 0 && (
            <>
              <LearnSkills skills={skills} lang={lang} />
              <SeparatorDots />
            </>
          )}

        </div>
        {/* EARN YOUR CREDENTIA */}
        {slug !== "technicalarchitect" && slug !== "applicationarchitect" && slug !== "systemarchitect" && (
          <div className="cert-site-bg-white slds-p-bottom--x-large">
            <div className="slds-m-top--x-large">
             <EarnCredential data={earnCredential} lang={lang} />
            </div>
            <div className="slds-container--x-large slds-container--center">
              {(reviewBoard == 'false')  && (maintainCredential > 0) &&  (
                <div className="slds-grid slds-wrap slds-grid--pull-padded-medium">
                  <div className="slds-size--1-of-1 architect-table-container">
                    <MaintenanceExams category={maintainCredential} lang={lang} slug={slug} />
                  </div>
                </div>
              )}
              {Boolean(reviewBoard == 'true') && (maintainCredential == 0) && (
                <div className="slds-grid slds-wrap slds-grid--pull-padded-medium">
                  <div className="slds-size--1-of-1 architect-table-container">
                    <ReviewBoards lang={lang} />
                  </div>
                </div>
              )}
              {Boolean(reviewBoard == 'true') && (maintainCredential > 0) && (
                <div className="slds-grid slds-wrap slds-grid--pull-padded-medium">
                  <div className="slds-size--1-of-1 slds-medium-size--1-of-2 architect-table-container">
                    <MaintenanceExams category={maintainCredential} lang={lang} slug={slug} />
                  </div>
                  <div className="slds-size--1-of-1 slds-medium-size--1-of-2 architect-table-container">
                    <ReviewBoards lang={lang} />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        

        {/* RELATED CREDENTIALS */}
        <div className="cert-site-bg-gray slds-p-top--x-large slds-p-bottom--xx-large">
          <div className="slds-text-align--center slds-m-top--small Fz(24) Ff(NeutrafaceDisplay-Titling)">
            {i18n.text('relatedCredentials_title')}
          </div>
          <div className="slds-p-around_large">
            <Credentials names={credentialsBadges} lang={lang} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CertificationsTemplate;
