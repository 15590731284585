import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../utils/I18n';
import Translation from '../utils/Translation';
import maintenanceExams from "../../data/maintenanceExams.json";

const MaintenanceExams = ({ category, lang, slug = '' }) => {
  const i18n = new I18n({ lang });
  const items = maintenanceExams.filter(it => it["category"] == category && it["lang"] == lang);
  const itemsDefault = maintenanceExams.filter(it => it["category"] == category && it["lang"] == "en");

  return (
    <div>
      <div className="slds-container--center MaintenanceExams-content slds-m-top--xx-large slds-m-bottom--xx-large slds-p-top--large">
        <div className="MaintenanceExams_subtitle maintain-credential-title">
          {i18n.text('maintainCredential_title')}
        </div>
        {(items.length > 0) && (
          <table className="MaintenanceExams-table">
            <thead>
              <tr>
                <th>{i18n.text('maintainCredential_releaseExam')}</th>
                <th>{i18n.text('maintainCredential_dateAvailable')}</th>
                <th>{i18n.text('maintainCredential_completionDeadline')}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (

                <tr key={`content_item_${index}`}>
                  {(item.link != '') && (
                    <td className="MaintenanceExams_link">
                      <a href={item.link} className="MaintenanceExams_link" target="_blank">
                        {item.title}
                      </a>
                    </td>
                  )}
                  {item.link == '' && (
                    <td>{item.title}</td>
                  )}
                  <td>{item.txt1}</td>
                  <td>{item.txt2}</td>
                </tr>
              ))}
              {items.length == 0 && itemsDefault.map((item, index) => (
                <tr key={`content_item_${index}`}>
                  {(item.link != '') && (
                    <td className="MaintenanceExams_link">
                      <a href={item.link} className="MaintenanceExams_link" target="_blank">
                        {item.title}
                      </a>
                    </td>
                  )}
                  {item.link == '' && (
                    <td>{item.title}</td>
                  )}
                  <td>{item.txt1}</td>
                  <td>{item.txt2}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {(slug === "mulesoftassociate" || slug === "mulesoftdeveloperi" || slug === "mulesoftdeveloperii"  || slug === "hyperautomationspecialist" || slug === "catalystspecialist"  || slug === "mulesoftintegrationarchitecti" || slug === "mulesoftplatformarchitecti") && (
        <div className='slds-text-align--center cert-earn_subtitle'>{i18n.text('maintainCredential_subTitle')}</div>
        )}
        
        <div className="MaintenanceExams-footer">
          <a href={i18n.text('maintainCredential_ctaLink')} target="_blank">
            {i18n.text('maintainCredential_ctaText')}
          </a>
        </div>
      </div>
    </div>
  );
};
export default MaintenanceExams;
