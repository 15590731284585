import React from 'react';
import I18n from '../utils/I18n';

const JoinCommunity = ({lang, slug}) => {
  const i18n = new I18n({ lang });
  
  let textLabel = i18n.text('joinTheCommunity_description')

  if(slug === "strategydesigner") {
    textLabel = i18n.text('joinTheCommunity_description_strategydesigner')
  }

  if(slug === "businessanalyst") {
    textLabel = i18n.text('joinTheCommunity_description_businessanalyst')
  }

  if(slug === "salesrepresentative") {
    textLabel = i18n.text('joinTheCommunity_description_salesrepresentative')
  }

  if(slug === "datacloudconsultant" || slug === "aispecialist") {
    textLabel = i18n.text('joinTheCommunity_description_datacloudconsultant')
  }

  if(slug === "mulesoftassociate" || slug === "mulesoftdeveloperi" || slug === "mulesoftdeveloperii" 
    || slug === "hyperautomationspecialist" || slug === "catalystspecialist" 
    || slug === "mulesoftintegrationarchitecti" || slug === "mulesoftplatformarchitecti") {
    textLabel = i18n.text('joinTheCommunity_description_mulesoft')
  }

  return (
    <div>
      <div className="slds-container--medium slds-container--center slds-m-top--x-large">
        <div className="joinCommunity-box">
          <div className="slds-text-align--center slds-m-bottom--x-small Fz(24) Fw(b)">
            {i18n.text('joinTheCommunity_title')}
          </div>
          <div className="slds-text-align--center Fz(18)" dangerouslySetInnerHTML={{__html: textLabel}} /> 
        </div>
      </div>
    </div>
  );
};

export default JoinCommunity;
