import React from 'react';
import Credentials from '../components/Credentials';
import I18n from '../utils/I18n';

const Prerequisites = ({data, text, lang, title, slug = ''}) => {
  const i18n = new I18n({ lang });

  //on some credentials, the pre-req custom text needs to be shown above the cards
  //while on others, it needs to be shown below
 const showAbove =( (title == 'B2B Solution Architect') || (slug === "mulesoftassociate" || slug === "mulesoftdeveloperi" || slug === "mulesoftdeveloperii"  || slug === "hyperautomationspecialist" || slug === "catalystspecialist"  || slug === "mulesoftintegrationarchitecti" || slug === "mulesoftplatformarchitecti") )

  return (
    <div>
      <div className="prerequisites-title slds-p-top--small">
        {i18n.text('prerequisites_title')}
      </div>
      {Boolean(text != '') &&  showAbove && (
        <div dangerouslySetInnerHTML={{__html: text}} className="slds-container--medium slds-container--center slds-text-align--center slds-m-top--medium Fz(18) slds-container--center"></div>
      )}
      <Credentials names={data} lang={lang} />
      {Boolean(text != '') && !showAbove &&(
        <div dangerouslySetInnerHTML={{__html: text}} className="slds-container--medium slds-container--center slds-text-align--center slds-m-top--medium Fz(18) slds-container--center"></div>
      )}
    </div>
  );
};

export default Prerequisites;
